import React from "react"
import {
  TwitterShareButton,
  TwitterIcon,
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
  TelegramShareButton,
  TelegramIcon
} from "react-share"
import * as S from "./styled"

const SocialShare = ({ post: { slug, title, description } }) => {
  const shareUrl = `https://marcoscury.com${slug}`
  const emailSubject = `Leia: ${title}`

  return (
    <S.SocialIconsWrapper>
      <TwitterShareButton url={shareUrl} title={title}>
        <TwitterIcon size="35" round={true} bgStyle={{ fill: "#272D2D" }} />
      </TwitterShareButton>
      <FacebookShareButton url={shareUrl} quote={description}>
        <FacebookIcon size="35" round={true} bgStyle={{ fill: "#272D2D" }} />
      </FacebookShareButton>
      <WhatsappShareButton url={shareUrl} title={title}>
        <WhatsappIcon size="35" round={true} bgStyle={{ fill: "#272D2D" }} />
      </WhatsappShareButton>
      <TelegramShareButton url={shareUrl} title={title}>
        <TelegramIcon size="35" round={true} bgStyle={{ fill: "#272D2D" }} />
      </TelegramShareButton>
      <EmailShareButton url={shareUrl} subject={emailSubject}>
        <EmailIcon size="35" round={true} bgStyle={{ fill: "#272D2D" }} />
      </EmailShareButton>
    </S.SocialIconsWrapper>
  )
}

export default SocialShare
