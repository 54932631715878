import styled from "styled-components"
import media from "styled-media-query"

export const PostWrapper = styled.section`
  height: 100%;
  background-color: #fafafa;
  /* padding: 1.8rem; */
  box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.25);
  /* border-radius: 1%; */
`

export const PostTitle = styled.h1`
  font-size: 2.5rem;
  font-weight: 700;
  padding: 1.3rem;
  font-family: "Montserrat";

  ${media.lessThan("medium")`
    font-size: 2.3rem;
  `}
`
export const SocialIconsWrapper = styled.div`
  padding-right: 1.3rem;

  button {
    margin-left: 0.5rem;
  }

  ${media.lessThan("medium")`
    padding-left: 1rem;
    padding-top: 1.5rem;
  `}
`

export const DateSocialWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
`

export const PostDate = styled.p`
  color: #555555;
  padding-left: 1.3rem;
`

export const PostAbstract = styled.p`
  margin-bottom: 2rem;
  line-height: 1.5rem;
  font-size: 1.2rem;
  font-style: italic;
  color: #555555;
  padding: 1.3rem;
  font-family: "Open Sans";
  border-bottom: 2px solid #dee1ec;
  letter-spacing: 0.059rem;
`

export const DisqusWrapper = styled.div`
  padding: 1.3rem;
`

export const PostContent = styled.div`
  font-size: 1.2rem;
  margin-bottom: 1rem;
  font-family: "Open Sans";

  p {
    line-height: 1.8rem;
    padding: 0 1.3rem 1.3rem 1.3rem;
    letter-spacing: 0.059rem;
  }

  img {
    max-width: 99%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  h1,
  h2,
  h3,
  h4,
  h6,
  h5 {
    font-weight: 800;
    letter-spacing: 0.069rem;
    line-height: 1.4;
    padding: 0 1.3rem 1.3rem 1.3rem;
  }
  h1 {
    font-size: 2.8rem;
    ${media.lessThan("large")`
      font-size: 1.875rem;
    `}
  }
  h2 {
    font-size: 2.1rem;
    ${media.lessThan("large")`
      font-size: 1.375rem;
    `}
  }
  h3 {
    font-size: 1.6rem;
    ${media.lessThan("large")`
      font-size: 1.125rem;
    `}
  }
  h4 {
    font-size: 1.4rem;
  }
  h5 {
    font-size: 1.2rem;
  }
  h6 {
    font-size: 1rem;
  }

  strong {
    font-weight: 700;
  }

  ul,
  ol {
    padding-left: 3.5rem;
    margin: 0 auto 1.6rem;
  }
  li {
    padding: 0.625rem 0;
    & > ul {
      margin-bottom: 0;
    }
  }
  p,
  li {
    code {
      word-wrap: break-word;
    }
  }

  ul {
    list-style: disc;
  }

  blockquote {
    border-left: 0.3rem solid gray;
    padding: 1.5rem;
    margin: 3.1rem;
    font-style: italic;
    background-color: #efefef;
    color: #000;
  }

  .twitter-tweet {
    margin: auto;
  }

  .gatsby-highlight {
    padding: 0 1.6rem 1.6rem;
    border: 0;
    ${media.lessThan("large")`
      padding: 0.1rem;
      font-size: 1.2rem;
    `}

    pre {
      border: none;
      border-radius: 0;
      box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
    }
  }

  a {
    border-bottom: 2px dashed #000;
    color: var(--highlight);
    text-decoration: none;
    text-decoration-style: double;
    font-weight: 700;
    transition: opacity 0.5s;
    svg {
      color: var(--postColor);
    }
    &:hover {
      opacity: 0.8;
    }
  }
`
