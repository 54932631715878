import React from "react"
import { graphql } from "gatsby"
import Post from "../components/Post"
import Layout from "../components/Layout"
import SEO from "../components/seo"

const BlogPost = ({ data }) => {
  const {
    markdownRemark: {
      frontmatter: { category, date, description, title, image },
      fields: { slug },
      html,
      timeToRead
    }
  } = data
  return (
    <Layout>
      <SEO title={title} description={description} image={image} />
      <Post post={{ title, html, date, description, timeToRead, slug }} />
    </Layout>
  )
}

export const query = graphql`
  query Post($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        category
        date(locale: "pt-br", formatString: "DD MMM YYYY")
        description
        title
        image
      }
      fields {
        slug
      }
      html
      timeToRead
    }
  }
`

export default BlogPost
