import React from "react"
import * as S from "./styled"
import SocialShare from "./social"
import { Disqus, CommentCount } from "gatsby-plugin-disqus"

const Post = ({
  post: { slug, date, description, title, html, timeToRead }
}) => {
  const disqusConfig = {
    url: `http://marcoscury.com${slug}`,
    identifier: slug,
    title: title
  }
  return (
    <S.PostWrapper>
      <S.PostTitle>{title}</S.PostTitle>
      <S.DateSocialWrapper>
        <S.PostDate>
          {date} - {timeToRead} min de leitura
        </S.PostDate>
        <SocialShare post={{ slug, description, title }} />
      </S.DateSocialWrapper>
      <S.PostAbstract>{description}</S.PostAbstract>
      <S.PostContent
        dangerouslySetInnerHTML={{
          __html: html.replace('src="assets', 'src="/assets')
        }}
      />
      <S.DisqusWrapper>
        <Disqus config={disqusConfig} />
      </S.DisqusWrapper>
    </S.PostWrapper>
  )
}

export default Post
